.merge-entities.ui.form .ui.header.header[data-v-0431282d] {
  margin: 0;
  padding-left: 0;
}
.merge-entities.ui.form .merged-entity-preview.column[data-v-0431282d] {
  padding-left: 1em;
}
.merge-entities.ui.form .entities-select .inline.field label[data-v-0431282d] {
  margin: 0;
  min-width: 100px;
}
.merge-entities.ui.form .entities-select .inline.field .ui.selection.dropdown[data-v-0431282d] {
  min-width: 175px;
  max-width: 230px;
}
.merge-entities.ui.form .entity-info[data-v-0431282d] {
  min-height: 300px;
}
.merge-entities.ui.form .entity-info .ui.block.header[data-v-0431282d] {
    margin: -1rem -1rem 1rem -1rem;
    padding-left: 1em;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
}
.merge-entities.ui.form .entity-info .ui.label[data-v-0431282d] {
    background: none;
}
.merge-entities.ui.form .entity-info .ui.list .item .header[data-v-0431282d] {
    margin-top: 0.7em;
    margin-bottom: 0.3em;
}
.merge-entities.ui.form .entity-info .ui.table[data-v-0431282d] {
    margin: 0 0 1em;
    border-radius: 0;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item[data-v-0431282d] {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
    padding: 0.5rem;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item[data-v-0431282d]:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item p[data-v-0431282d] {
      margin-bottom: 0;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item p > .ui.checkbox[data-v-0431282d]:first-child {
        vertical-align: -4px;
        margin-right: 0.5rem;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item p ~ .ui.checkbox.toggle[data-v-0431282d] {
        margin-left: 0.5rem;
        width: 9rem;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item .custom-list[data-v-0431282d] {
      margin-left: 2em;
}
.merge-entities.ui.form .entity-info .list .item .content > div.info-item.preferences[data-v-0431282d] {
    border: none;
}
.merge-entities.ui.form .entity-info .no-entity[data-v-0431282d] {
    text-align: center;
}
.merge-entities.ui.form .back-next-buttons[data-v-0431282d] {
  float: right;
}
.merge-entities.ui.form .ui.selection.dropdown > .text[data-v-0431282d],
.merge-entities.ui.form .ui.list > .item > .content > .info-item[data-v-0431282d] {
  word-break: break-all;
}
